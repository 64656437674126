import React from 'react';
import './DigitalService.css';

// Importing icons with circular backgrounds
import WebIcon from '../../../assets/digital/web-icon.png';
import MarketingIcon from '../../../assets/digital/digital-icon.png';
import DesignIcon from '../../../assets/digital/design-icon.png';
import AiIcon from '../../../assets/digital/ai-icon.png';

const ServicesSection = () => {
  return (
    <section className="digital-services-section">
      <h2>Explorer Nos <span className="digital-highlighted">Services</span></h2>
      <p>Nos Services Donneront Vie À Votre Entreprise.</p>

      <div className="digital-categories">
        {/* Web Development Card */}
        <div className="digital-card digital-card-web">
          <img src={WebIcon} alt="Web Icon" className="digital-card-icon" />
          <h3>Développement Web</h3>
          <p>Optimiser Votre Présence En Ligne Grâce À Des Solutions Web Complètes</p>
          <div className="digital-tags">
            <span>Web Design</span>
            <span>Front-End</span>
            <span>Back-End</span>
            <span>E-commerce</span>
            <span>Maintenance et Support</span>
          </div>
        </div>

        {/* Marketing Card */}
        <div className="digital-card digital-card-marketing">
          <img src={MarketingIcon} alt="Marketing Icon" className="digital-card-icon" />
          <h3>Marketing Digital</h3>
          <p>Maximisez Votre Impact Avec Des Stratégies Adaptées À Chaque Plateforme</p>
          <div className="digital-tags">
            <span>Social Media Management</span>
            <span>SEO</span>
            <span>Email Marketing</span>
            <span>Publicité Display et Native</span>
          </div>
        </div>

        {/* Graphic Design Card */}
        <div className="digital-card digital-card-design">
          <img src={DesignIcon} alt="Design Icon" className="digital-card-icon" />
          <h3>Design Graphique</h3>
          <p>Mettez En Valeur Votre Image De Marque Avec Des Designs Impactants</p>
          <div className="digital-tags">
            <span>Création Visuelle</span>
            <span>Branding</span>
            <span>Conception de Logos</span>
          </div>
        </div>

        {/* AI Integration Card */}
        <div className="digital-card digital-card-ai">
          <img src={AiIcon} alt="AI Icon" className="digital-card-icon" />
          <h3>Intégration D'IA</h3>
          <p>Automatisez Et Améliorez Vos Processus Grâce À L’Intelligence Artificielle</p>
          <div className="digital-tags">
            <span>Automatisation des Tâches</span>
            <span>Analyse Prédictive</span>
            <span>Chatbots et Service Client</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
