import React from "react";
import "./HeroSectionService.css";
import arrow from "../../../assets/services/arrow-exploreservice.svg";
import servicesHeroImage from "../../../assets/services/ServicesHero.png";

const ServicesHero = () => {
  return (
    <section className="hero-section-service">
    <div className="text-content">
      <h1>Allier Innovation Et Excellence Pour Votre Réussite</h1>
      <p>
        Des Solutions Sur Mesure Et Un Soutien Dédié Inspirés Par Nos Valeurs
        De Fiabilité, Créativité Et Proximité
      </p>
      <div className="explore-link">
        <img src={arrow} alt="Explore arrow" />
        <span>Explore Our Services</span>
      </div>
    </div>
    <div className="image-content">
      <img src={servicesHeroImage} alt="Services Hero" />
    </div>
  </section>
);
};

export default ServicesHero;
