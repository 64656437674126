// Digital.js
import React, { useEffect } from 'react';
import HeroSectionDigital from '../components/Digital/HeroSectionDigital/HeroSectionDigital';
import DigitalService from '../components/Digital/DigitalService/DigitalService';

import CaseStudiesSection from '../components/Digital/CaseStudies/CaseStudies';
import DifferenceSection from '../components/Digital/DifferenceSection/DifferenceSection';
import PackDigitalSection from '../components/Digital/PackDigitalSection/PackDigitalSection';
import ContactSection from '../components/ContactSection/ContactSection';
import FooterSection from '../components/Footer/Footer';
import './Digital.css';

const Digital = () => {
  useEffect(() => {
    // Apply specific class to the body when this page is active
    document.body.classList.add('digital-page-active');
    return () => {
      // Remove the class when the component is unmounted
      document.body.classList.remove('digital-page-active');
    };
  }, []);

  return (
    <div className="digital-page">
      <HeroSectionDigital />
      <DigitalService />
      <CaseStudiesSection />
      <DifferenceSection />
      <PackDigitalSection />
      <ContactSection />
      <FooterSection />
      
    </div>
  );
};

export default Digital;
