import React from 'react';
import './HeroSection.css';

// Import images
import image2 from '../../assets/card-image-2.png'; // Image for "Formation IT et Digitale"
import image3 from '../../assets/card-image-1.png'; // Image for third card
import image4 from '../../assets/card-image-3.png'; // Image for fourth card
import image5 from '../../assets/card-image-4.png'; // Image for fifth card

// Import SVG icons
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg'; // Arrow icon
import { ReactComponent as IconPresence } from '../../assets/icons/icon-presence.svg'; // Presence Digitale icon
import { ReactComponent as ArrowCards } from '../../assets/icons/arrow-cards.svg';
import { ReactComponent as ArrowCardsWhite } from '../../assets/icons/arrow-card-white.svg';
import bellIcon from '../../assets/icons/bell.svg';  // Bell icon for Support Technique
import shieldIcon from '../../assets/icons/shield.svg';  // Shield icon for Support et Suivi Personnalisés
import ellipseIcon from '../../assets/icons/Ellipse-bento.svg'; // Ellipse background

const HeroSection = () => {
  const scrollToWhatWeDo = () => {
    const element = document.getElementById('what-we-do-section');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };




  return (
    <section className="hero-section">
      {/* Hero Header */}
      <div className="hero-header">
        <h1>Une Solution Tout-En-Un Pour Vos Besoins Digitaux Et IT</h1>
        <div className="cta-wrapper">
          <button className="cta-button"    onClick={scrollToWhatWeDo}>
            <div className="circular-arrow">
              <ArrowRight className="arrow-icon" />
            </div>
          </button>
          <span className="cta-text">Get Started</span>
        </div>
      </div>

      {/* Bento Grid */}
      <div className="bento-grid">
        {/* First Bento: Présence Digitale */}
        <div className="bento-item bento-item-1">
          <div className="icon-wrapper">
            <img src={ellipseIcon} alt="Ellipse background" className="ellipse-background" />
            <IconPresence className="icon-presence" /> {/* SVG Icon for Présence Digitale */}
          </div>
          <h2>Présence Digitale</h2>
          <p>Solutions Digitales Sur Mesure Pour Votre Entreprise</p>
          <ArrowCards className="icon-arrow" /> {/* SVG Arrow Icon */}
        </div>

        {/* Second Bento: Formation IT et Digitale */}
        <div className="bento-item bento-item-2">
          <div className="bento-text">
            <h2>Formation IT et Digitale</h2>
          </div>
          <div className="bento-image">
            <img src={image2} alt="Formation IT et Digitale" />
          </div>
        </div>

        {/* Third Bento with Image */}
        <div className="bento-item bento-item-3">
          <img src={image3} alt="Image for Card 3" className="bento-image-full" />
        </div>

        {/* Fourth Bento Item: Add image4 */}
        <div className="bento-item bento-item-4">
          <img src={image4} alt="Support et Suivi Personnalisés" className="bento-image-full" />
          <div className="icon-wrapper">
            <img src={ellipseIcon} alt="Ellipse background" className="ellipse-background" />
            <img src={shieldIcon} alt="Support et Suivi Personnalisés" className="bento-icon" />
          </div>
          <h2>Support et Suivi Personnalisés</h2>
          <ArrowCardsWhite className="icon-arrow" /> {/* SVG Arrow Icon */}
        </div>

        {/* Fifth Bento Item: Add image5 */}
        <div className="bento-item bento-item-5">
          <img src={image5} alt="Support Technique" className="bento-image-full" />
          <div className="icon-wrapper">
            <img src={ellipseIcon} alt="Ellipse background" className="ellipse-background" />
            <img src={bellIcon} alt="Support Technique" className="bento-icon" />
          </div>
          <h2>Support Technique</h2>
          <ArrowCardsWhite className="icon-arrow" /> {/* SVG Arrow Icon */}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
