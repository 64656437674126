import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './Footer.css';
import logo from '../../assets/logoContact.png';
import circleIllustration from '../../assets/Footer-illustration.png';
import instagramIcon from '../../assets/footer-insta.png';
import facebookIcon from '../../assets/footer-fb.png';
import linkedinIcon from '../../assets/footer-linkedin.png';

const FooterSection = () => {
  return (
    <div className="footer-section-fs">
      {/* Illustration Section */}
      <div className="footer-illustration-fs">
        <img src={circleIllustration} alt="Circle Illustration" className="circle-illustration-fs" />
      </div>

      {/* Navigation Links */}
      <div className="footer-nav-fs">
        <Link to="/formation">FORMATION</Link> {/* Use Link for internal navigation */}
        <Link to="/digital">DIGITALISATION</Link> {/* Use Link for internal navigation */}
        <Link to="/services">SERVICES</Link> {/* Use Link for internal navigation */}
        <Link to="/contact"> {/* Use Link for internal navigation */}
          <button className="contact-button-fs">CONTACT US</button>
        </Link>
      </div>

      {/* Social Media Links */}
      <div className="footer-social-fs">
        <a href="https://www.instagram.com/teamelghazi_/">
          <img src={instagramIcon} alt="Instagram" />
          INSTAGRAM
        </a>
        <a href="https://www.facebook.com/p/Team-ElGhazi-61553702234132/">
          <img src={facebookIcon} alt="Facebook" />
          FACEBOOK
        </a>
        <a href="https://ma.linkedin.com/company/teamelghazi">
          <img src={linkedinIcon} alt="LinkedIn" />
          LINKEDIN
        </a>
      </div>
    </div>
  );
};

export default FooterSection;
