import React, { useEffect } from 'react';
import HeroSectionFormation from '../components/Formation/HeroSectionFormation/HeroSectionFormation';
import FormationCards from '../components/Formation/FormationCards/FormationCards';
import ContactSection from '../components/ContactSection/ContactSection';
import FooterSection from '../components/Footer/Footer';

import './Formation.css';

const Formation = () => {
  useEffect(() => {
    document.body.classList.add('formation-page-active');
    return () => {
      document.body.classList.remove('formation-page-active');
    };
  }, []);

  return (
    <div className="formation-page">
      <HeroSectionFormation />
      <FormationCards />
      <ContactSection />
      <FooterSection />
      
    </div>
  );
};

export default Formation;
