import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './FormationCards.css';
import formationIcon from '../../../assets/formation/formation-icon.png';
import icon1 from '../../../assets/formation/icon1.png';
import icon2 from '../../../assets/formation/icon2.png';
import image1 from '../../../assets/formation/image1.png';
import image2 from '../../../assets/formation/image2.png';

const formations = [
  {
    icon: icon1,
    title: 'Formation Business Intelligence',
    description:
      "L'intelligence économique inclut l'analyse des données et l'analyse commerciale, mais elle les utilise uniquement comme parties du processus global. Elle aide les utilisateurs à tirer des conclusions des données...",
    image: image1,
    buttonColor: '#FF6F61',
    tags: ['Python', 'PowerBI'],
  },
  {
    icon: icon2,
    title: 'Formation en Data Science',
    description:
      "Apprenez les bases de la science des données, de l'apprentissage automatique et de l'intelligence artificielle pour prendre des décisions éclairées et basées sur les données...",
    image: image2,
    buttonColor: '#6A9AD9',
    tags: ['Python', 'TensorFlow', 'Machine Learning'],
  },
];

const FormationSection = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  // Function to handle the button click and navigate to the contact page
  const goToContact = () => {
    navigate('/contact');
  };

  return (
    <section className="fs-formation-section">
      <h2 className="fs-formation-title">
        <img
          src={formationIcon}
          alt="Icône de Formation"
          className="fs-formation-title-icon"
        />
        Explorez Nos <span>Formations</span>
      </h2>
      <p className="fs-formation-subtitle">
        Nos services donneront vie à votre entreprise
      </p>

      <div className="fs-formation-cards">
        {formations.map((formation, index) => (
          <div key={index} className="fs-formation-card">
            <div className="fs-formation-content">
              <div className="fs-formation-header">
                <img
                  src={formation.icon}
                  alt={`${formation.title} Icon`}
                  className="fs-formation-icon"
                />
                <h3 className="fs-formation-card-title">{formation.title}</h3>
              </div>
              <p className="fs-formation-card-description">
                {formation.description}
              </p>
              <div className="fs-formation-tags">
                {formation.tags.map((tag, tagIndex) => (
                  <span key={tagIndex} className="fs-formation-tag">
                    {tag}
                  </span>
                ))}
              </div>
            </div>
            <div className="fs-formation-image-container">
              <img
                src={formation.image}
                alt={`${formation.title} Illustration`}
                className="fs-formation-image"
              />
              {/* Keep the button structure unchanged for the styling to be preserved */}
              <button
                className="fs-formation-button"
                style={{ backgroundColor: formation.buttonColor }}
                onClick={goToContact} // Navigate to the contact page on button click
              >
                Commencer <span className="fs-formation-button-arrow">→</span>
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FormationSection;
