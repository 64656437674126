import React from 'react';
import './CaseStudies.css';

// Importing images
import CaseStudyImage1 from '../../../assets/digital/913.jpg';
import CaseStudyImage2 from '../../../assets/digital/netrunner.jpg';

const CaseStudiesSection = () => {
  return (
    <section className="case-studies-section">
      <h2>
        Case <span className="case-highlight">Studies</span>
      </h2>
      <p>Découvrez Comment Notre Design Améliore La Présence En Ligne De Nos Clients</p>

      <div className="case-study">
        <div className="case-study-content">
          <h3>913.ai</h3>
          <p>
            913.ai est une entreprise spécialisée dans les infrastructures d'agents intelligents pour l'automatisation des entreprises. Notre mission consiste à repenser l'identité de marque vieillissante de 913.ai en insufflant une apparence fraîche et moderne, reflétant l’esprit d’innovation et l’engagement de l’entreprise envers l’automatisation avancée.
          </p>
          <div className="tags">
            <span>Design De Marque Et De Site Web</span>
          </div>
        </div>
        <div className="case-study-image">
          <img src={CaseStudyImage1} alt="913.ai Case Study" />
        </div>
      </div>

      <div className="case-study">
        <div className="case-study-content">
          <h3>Netrunner</h3>
          <p>
            Netrunner, un cabinet de conseil en informatique de pointe, se distingue dans le développement de logiciels pour une clientèle variée d’entreprises spécialisées dans les secteurs de la santé, des assurances et de la biotechnologie. L’expertise de Netrunner réside dans la création de solutions logicielles sur-mesure qui répondent aux exigences complexes de ces industries de haute spécialisation.
          </p>
          <div className="tags">
            <span>Conception De L'Identité De Marque</span>
          </div>
        </div>
        <div className="case-study-image">
          <img src={CaseStudyImage2} alt="Netrunner Case Study" />
        </div>
      </div>
    </section>
  );
};

export default CaseStudiesSection;
