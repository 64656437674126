import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import logoIcon from '../../assets/logo-icon.png';
import logoText from '../../assets/logo-text.svg';
import MenuSvg from '../../assets/menu-icon.svg'; // Your SVG for the hamburger menu
import CloseSvg from '../../assets/close-icon.svg'; // Your SVG for the close button
import './Navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    if (menuOpen) {
      setMenuOpen(false);
      enablePageScroll();
    } else {
      setMenuOpen(true);
      disablePageScroll();
    }
  };


  const handleContactClick = () => {
    setMenuOpen(false); // Close menu if open
    enablePageScroll(); // Enable scrolling
    navigate('/contact'); // Navigate to contact page
  }

  return (
    <header className="navbar">
      {/* Logo on the left */}
      <div className="logo-container">
        <img src={logoIcon} alt="Logo Icon" className="logo-icon" />
        <img src={logoText} alt="Logo Text" className="logo-text" />
      </div>

      {/* Navigation Links */}
      <nav className={`nav-center ${menuOpen ? 'open' : ''}`}>
        <ul className="nav-links" onClick={() => setMenuOpen(false)}>
          <li><NavLink exact to="/" activeClassName="active">Home</NavLink></li>
          <li><NavLink to="/digital" activeClassName="active">Digital</NavLink></li>
          <li><NavLink to="/services" activeClassName="active">Services</NavLink></li>
          <li><NavLink to="/formation" activeClassName="active">Formation</NavLink></li>
        </ul>
      </nav>

      {/* Contact Button and Menu Toggle */}
      <div className="menu-contact">
        <button onClick={handleContactClick} className={`contact-button ${menuOpen ? 'contact-button-hidden' : ''}`}>
          Contact
        </button>
        <button className="menu-toggle" onClick={toggleMenu}>
          {menuOpen ? <img src={CloseSvg} alt="Close Menu" /> : <img src={MenuSvg} alt="Open Menu" />}
        </button>
      </div>
    </header>
  );
};

export default Navbar;
