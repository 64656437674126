import React from 'react';
import './HeroSectionFormation.css';
import lineSvg from '../../../assets/formation/line-decoration.svg';
import jsIcon from '../../../assets/formation/js-icon.png';
import pythonIcon from '../../../assets/formation/python-icon.png';
import databaseIcon from '../../../assets/formation/database-icon.png';
import terminalIcon from '../../../assets/formation/terminal-icon.png';
import arrowDownIcon from '../../../assets/formation/arrow-down-icon.svg';

const HeroSectionFormation = () => {
  return (
    <section className="fs-hero-section-formation">
      <div className="fs-subheader">
        <img src={lineSvg} alt="Line Decoration" className="fs-line-decoration fs-left" />
        Plusieurs Formations Dans Divers Domaines
        <img src={lineSvg} alt="Line Decoration" className="fs-line-decoration fs-right" />
      </div>
      <h1>
        Donnez Du Pouvoir À Votre Équipe Avec Des Formations <span className="fs-digital-text">Digitales</span> De Pointe
      </h1>
      <div className="fs-explore-section">
        <div className="fs-explore-box">
          <img src={arrowDownIcon} alt="Arrow Down" className="fs-arrow-icon" />
          <span className="fs-explore-text">
            Explore Our Services And Work
          </span>
        </div>
      </div>
      {/* Floating icons */}
      <div className="fs-floating-icons">
        <img src={jsIcon} alt="JS Icon" className="fs-icon fs-js-icon" />
        <img src={pythonIcon} alt="Python Icon" className="fs-icon fs-python-icon" />
        <img src={databaseIcon} alt="Database Icon" className="fs-icon fs-database-icon" />
        <img src={terminalIcon} alt="Terminal Icon" className="fs-icon fs-terminal-icon" />
      </div>
    </section>
  );
};

export default HeroSectionFormation;
