import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './ContactSection.css';
import logo from '../../assets/logoContact.png';

const ContactUsSection = () => {
  return (
    <div className="contact-us-section-cus">
      <div className="contact-us-left-cus">
        <img src={logo} alt="Logo" className="contact-us-logo-cus" />
        <div className="contact-us-text-cus">
          <p>Prenez contact avec TEAMELGHAZI dès maintenant</p>
          <h3>Contact Us now and get free consultation</h3>
        </div>
      </div>
      <div className="contact-us-right-cus">
        <Link to="/contact"> {/* Link to the Contact page */}
          <button className="contact-us-button-cus">Get in Touch Now</button>
        </Link>
      </div>
    </div>
  );
};

export default ContactUsSection;
