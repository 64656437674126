import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./ContactPage.css";
import cartIcon from "../assets/cart-icon.svg";
import FooterSection from "../components/Footer/Footer";

const ContactPage = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    product: "",
  });

  const [selectedProduct, setSelectedProduct] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const products = [
    "Pack Digital - Social Media",
    "Pack Digital - Advertising",
    "Pack Digital - SEO Optimization",
    "Formation IT/Digitale",
    "Formation Gestion de Projet",
    "IT Support",
    "Book Design",
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const product = queryParams.get("product");

    if (product) {
      setSelectedProduct(product);
      setFormData((prevData) => ({ ...prevData, product }));
    }
  }, [location]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);

    setSuccessMessage(
      `Merci ! Votre demande pour "${selectedProduct || formData.product}" a été envoyée.`
    );
    setFormData({
      fullName: "",
      email: "",
      phone: "",
      product: "",
    });
    setSelectedProduct("");
  };

  return (
    <div className="contact-page">
      <div className="contact-left">
        <h1>Votre Partenaire Digital pour une Transformation Réussie</h1>
        <p>
          Bénéficiez d’un accompagnement personnalisé, de solutions innovantes
          et d’une expertise technique pour faire évoluer votre entreprise dans
          le monde numérique.
        </p>
        {selectedProduct && (
            <div className="selected-service">
              <img src={cartIcon} alt="Cart Icon" className="service-cart-icon" />
              <div>
                <p className="service-title">Service Choisi :</p>
                <p className="service-name">{selectedProduct}</p>
              </div>
            </div>
          )}

      </div>

      <div className="contact-right">
        <h2>
          Nous Contacter – Ensemble, Réalisons Vos Ambitions Digitales
        </h2>
        <p>
          Remplissez le formulaire ci-dessous pour nous envoyer votre demande.
          Notre équipe vous répondra dans les plus brefs délais.
        </p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="fullName">Nom Complet</label>
            <input
              id="fullName"
              type="text"
              name="fullName"
              placeholder="Entrez votre nom complet"
              value={formData.fullName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              name="email"
              placeholder="Entrez votre email valide"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Téléphone</label>
            <input
              id="phone"
              type="tel"
              name="phone"
              placeholder="Entrez votre numéro de téléphone"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>
          {!selectedProduct && (
            <div className="form-group">
              <label htmlFor="product">Type de Demande</label>
              <select
                id="product"
                name="product"
                value={formData.product}
                onChange={handleInputChange}
                required
              >
                <option value="">Sélectionnez votre service</option>
                {products.map((product, index) => (
                  <option key={index} value={product}>
                    {product}
                  </option>
                ))}
              </select>
            </div>
          )}
          <button type="submit" className="submit-button">
            Contactez-Nous Dès Maintenant
          </button>
        </form>
        {successMessage && <p className="success-message">{successMessage}</p>}
      </div>

      
    </div>

                  
  );
};

export default ContactPage;
