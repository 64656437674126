import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './PackDigitalSection.css';
import arrowIcon from '../../../assets/digital/arrow-getstarted.svg';
import checkIcon from '../../../assets/digital/check-icon.png';

const packs = [
  {
    title: 'Pack Réseaux Sociaux',
    description: 'Boostez Votre Engagement Et Bâtissez Une Communauté Active Sur Les Réseaux Sociaux',
    price: '5000 DH / mois',
    services: ['Gestion De Contenu Social', 'Analyse Des Performances'],
    buttonColor: '#FFD399',
    backgroundClass: 'pack-card-social',
    footerText: 'Parfait Pour Les Entreprises Cherchant À Renforcer Leur Visibilité Et Interaction Sur Les Réseaux Sociaux',
  },
  {
    title: 'Pack Publicité',
    description: 'Optimisez Vos Campagnes Et Générez Un Retour Sur Investissement Élevé Avec Des Annonces Stratégiques',
    price: '10000 DH / mois',
    services: ['Création De Contenu Publicitaire', 'Gestion De Campagnes'],
    buttonColor: '#FFCAD4',
    backgroundClass: 'pack-card-publicity',
    footerText: 'Optimisez Vos Campagnes Et Générez Un Retour Sur Investissement Élevé Avec Des Annonces Stratégiques',
  },
  {
    title: 'Pack Site Web',
    description: 'Développez Une Plateforme Performante Et Orientée Client, Optimisée Pour Des Résultats Durables',
    price: '30000 DH / mois',
    services: ['Conception Et Optimisation De Site', 'Maintenance Support Et Sécurité'],
    buttonColor: '#C1E7FF',
    backgroundClass: 'pack-card-site',
    footerText: 'Recommandé Pour Les Entreprises Souhaitant Bâtir Un Site Web Robuste Et Optimiser Leur Présence En Ligne',
  },
];

export default function PackDigitalSection() {
  const navigate = useNavigate(); // Hook for navigation

  const handleGetStarted = (packTitle) => {
    navigate(`/contact?product=${encodeURIComponent(packTitle)}`); // Navigate to contact page with pack title
  };

  return (
    <section className="pack-digital-section">
      <h2 className="pack-section-title">
        Choisissez Le <span className="pack-highlight">Pack</span> Qui Vous Convient
      </h2>
      <p className="pack-section-subtitle">
        Des Offres Flexibles Pour Accompagner Votre Entreprise, Quel Que Soit Votre Besoin
      </p>

      <div className="pack-cards">
        {packs.map((pack, index) => (
          <div key={index} className={`pack-card ${pack.backgroundClass}`}>
            <h3>{pack.title}</h3>
            <p className="pack-description">{pack.description}</p>
            <div className="pack-price">
              <span>{pack.price}</span>
              <small className="gotu-text">Pause Or Cancel Anytime</small>
            </div>
            <div className="pack-services">
              <p className="gotu-text">Service Included</p>
              {pack.services.map((service, i) => (
                <div key={i} className="service-item">
                  {service}
                </div>
              ))}
            </div>
            <button
              className="get-started-button"
              style={{ backgroundColor: pack.buttonColor }}
              onClick={() => handleGetStarted(pack.title)} // Handle button click
            >
              Get Started <img src={arrowIcon} alt="arrow icon" />
            </button>
            <p className="pack-footer">
              <img src={checkIcon} alt="check icon" className="footer-check-icon" />
              {pack.footerText}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}
