import React, { useState, useRef } from 'react';
import './FAQ.css';

const FAQSection = () => {
  const [openQuestion, setOpenQuestion] = useState(null);
  const answerRefs = useRef([]);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h2>Questions Fréquemment Posées</h2>

      {/* FAQ Card 1 */}
      <div className={`faq-card ${openQuestion === 0 ? 'expanded' : ''}`} onClick={() => toggleQuestion(0)}>
        <div className={`faq-question ${openQuestion === 0 ? 'open-header' : ''}`}>
          <span>Quels sont les services de digitalisation que vous offrez ?</span>
          <span className="toggle-icon">{openQuestion === 0 ? '−' : '+'}</span>
        </div>
        <div
          ref={(el) => (answerRefs.current[0] = el)}
          className={`faq-answer ${openQuestion === 0 ? 'open' : ''}`}
          style={{
            height: openQuestion === 0 ? `${answerRefs.current[0]?.scrollHeight}px` : '0',
          }}
        >
          Nous analysons vos besoins spécifiques et vous proposons des solutions sur mesure pour vos projets numériques. Nous vous accompagnons tout au long du processus, de la conception à la mise en œuvre, avec des sessions de mentorat personnalisées.
        </div>
      </div>

      {/* FAQ Card 2 */}
      <div className={`faq-card ${openQuestion === 1 ? 'expanded' : ''}`} onClick={() => toggleQuestion(1)}>
        <div className={`faq-question ${openQuestion === 1 ? 'open-header' : ''}`}>
          <span>Comment fonctionne l’accompagnement personnalisé ?</span>
          <span className="toggle-icon">{openQuestion === 1 ? '−' : '+'}</span>
        </div>
        <div
          ref={(el) => (answerRefs.current[1] = el)}
          className={`faq-answer ${openQuestion === 1 ? 'open' : ''}`}
          style={{
            height: openQuestion === 1 ? `${answerRefs.current[1]?.scrollHeight}px` : '0',
          }}
        >
          Nous analysons vos besoins spécifiques et vous proposons des solutions sur mesure pour vos projets numériques. Nous vous accompagnons tout au long du processus, de la conception à la mise en œuvre, avec des sessions de mentorat personnalisées.
        </div>
      </div>

      {/* FAQ Card 3 */}
      <div className={`faq-card ${openQuestion === 2 ? 'expanded' : ''}`} onClick={() => toggleQuestion(2)}>
        <div className={`faq-question ${openQuestion === 2 ? 'open-header' : ''}`}>
          <span>Pouvez-vous m’aider avec la création de manuels scolaires ?</span>
          <span className="toggle-icon">{openQuestion === 2 ? '−' : '+'}</span>
        </div>
        <div
          ref={(el) => (answerRefs.current[2] = el)}
          className={`faq-answer ${openQuestion === 2 ? 'open' : ''}`}
          style={{
            height: openQuestion === 2 ? `${answerRefs.current[2]?.scrollHeight}px` : '0',
          }}
        >
          Oui, nous avons une expertise dans la rédaction et la conception de manuels scolaires et de supports éducatifs en informatique. Nous travaillons avec des établissements éducatifs pour développer des contenus adaptés aux programmes.
        </div>
      </div>

      {/* FAQ Card 4 */}
      <div className={`faq-card ${openQuestion === 3 ? 'expanded' : ''}`} onClick={() => toggleQuestion(3)}>
        <div className={`faq-question ${openQuestion === 3 ? 'open-header' : ''}`}>
          <span>Comment puis-je commencer une collaboration avec vous ?</span>
          <span className="toggle-icon">{openQuestion === 3 ? '−' : '+'}</span>
        </div>
        <div
          ref={(el) => (answerRefs.current[3] = el)}
          className={`faq-answer ${openQuestion === 3 ? 'open' : ''}`}
          style={{
            height: openQuestion === 3 ? `${answerRefs.current[3]?.scrollHeight}px` : '0',
          }}
        >
          Pour débuter une collaboration, il vous suffit de nous contacter via notre formulaire de contact ou par email. Nous vous recontacterons pour discuter de vos besoins et vous proposer une solution adaptée.
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
