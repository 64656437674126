import React from 'react';
import './WhatWeCanDo.css';

const ServicesSectionWcd = () => {
  return (
    <div className="services-section-wcd">
      <h2>Ce Que Nous Pouvons Faire Pour Vous</h2>
      <div className="services-cards-wcd">
        
        {/* Card 1 */}
        <div className="service-card-wcd service-card-marketing-wcd">
          <div className="card-header-wcd header-marketing-wcd">Marketing</div>
          <ul>
            <li>Création De Graphiques Pour Les Réseaux Sociaux</li>
            <li>Conception D'emails</li>
            <li>Publicités Digitales</li>
            <li>Infographies</li>
            <li>eBooks Et Rapports Digitaux</li>
          </ul>
        </div>

        {/* Card 2 */}
        <div className="service-card-wcd service-card-branding-wcd">
          <div className="card-header-wcd header-branding-wcd">Branding</div>
          <ul>
            <li>Création De Logos Et Branding</li>
            <li>Guides De Marque</li>
            <li>Présentations Visuelles</li>
          </ul>
        </div>

        {/* Card 3 */}
        <div className="service-card-wcd service-card-uxui-wcd">
          <div className="card-header-wcd header-uxui-wcd">UX/UI</div>
          <ul>
            <li>Wireframes</li>
            <li>Prototypes</li>
            <li>Pages D'atterrissage</li>
            <li>Sites Web</li>
            <li>Applications Mobiles</li>
            <li>Systèmes De Design</li>
            <li>Photos D'archives</li>
            <li>Transfert Aux Développeurs</li>
          </ul>
        </div>

        {/* Card 4 */}
        <div className="service-card-wcd service-card-webdev-wcd">
          <div className="card-header-wcd header-webdev-wcd">Développement Web et Mobile</div>
          <ul>
            <li>Conception Et Développement De Sites Web</li>
            <li>Développement D'applications Mobiles Sur Mesure</li>
            <li>Intégration De Systèmes Et Fonctionnalités Avancées</li>
            <li>Optimisation Des Performances Et SEO</li>
          </ul>
        </div>

        {/* Card 5 */}
        <div className="service-card-wcd service-card-ai-wcd">
          <div className="card-header-wcd header-ai-wcd">Intégration et Optimisation de l'IA</div>
          <ul>
            <li>Automatisation Intelligente</li>
            <li>Solutions Basées Sur L'IA Pour Améliorer L'efficacité</li>
            <li>Optimisation Des Processus Avec L'intelligence Artificielle</li>
            <li>Développement De Solutions IA Sur Mesure Pour Vos Besoins</li>
          </ul>
        </div>

        {/* Card 6 */}
        <div className="service-card-wcd service-card-it-wcd">
          <div className="card-header-wcd header-it-wcd">Formation IT et Digitale</div>
          <ul>
            <li>Cours Sur Les Technologies Web Et Mobiles</li>
            <li>Intégration De L'IA Dans Vos Processus</li>
            <li>Ateliers Pratiques Sur Le Développement Digital</li>
            <li>Renforcement Des Compétences IT De Vos Équipes</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServicesSectionWcd;
