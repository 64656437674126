import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './ServicesCards.css';
import cardImage1 from '../../../assets/services/support-image.png';
import cardImage2 from '../../../assets/services/Book-image.png';

const ServiceCards = () => {
  return (
    <section className="service-cards-section">
      {/* Card 1 */}
      <div className="service-cards-card">
        <div className="service-cards-content">
          <h3>Support Technique</h3>
          <p>
            Assistance Rapide Et Efficace Pour Tous Vos Besoins IT. Maintenance,
            Dépannage Et Optimisation 24/7.
          </p>
          {/* Link for the "Contactez-Nous" button to navigate to the contact page */}
          <Link to="/contact">
            <button className="service-cards-cta-button">Contactez-Nous</button>
          </Link>
        </div>
        <div className="service-cards-image">
          <img src={cardImage1} alt="Support Technique" />
        </div>
      </div>

      {/* Card 2 */}
      <div className="service-cards-card">
        <div className="service-cards-content">
          <h3>Création De Contenus</h3>
          <p>
            Rédaction Et Conception De Livres, Manuels Et Supports Éducatifs
            Adaptés À Vos Besoins.
          </p>
          {/* Link for the "Travaillez Avec Nous" button to navigate to the services page */}
          <Link to="/contact">
            <button className="service-cards-cta-button">Travaillez Avec Nous</button>
          </Link>
        </div>
        <div className="service-cards-image">
          <img src={cardImage2} alt="Création De Contenus" />
        </div>
      </div>
    </section>
  );
};

export default ServiceCards;
