import React from 'react';
import './WhyChooseUs.css';
import IconWhy1 from '../../assets/icon-why-1.png';
import IconWhy2 from '../../assets/icon-why-2.png';
import IconWhy3 from '../../assets/icon-why-3.png';
import MaskWhy1 from '../../assets/mask-why-1.png';
import MaskWhy2 from '../../assets/mask-why-2.png';
import MaskWhy3 from '../../assets/mask-why-3.png';

const PourquoiChoisir = () => {
  return (
    <div className="pourquoi-choisir-section">
      <h2>Pourquoi choisir teamelghazi</h2>
      <p className="pourquoi-choisir-intro">
        TeamElGhazi est votre partenaire idéal pour naviguer dans le monde numérique.
        Avec notre approche innovante, une flexibilité adaptée à vos besoins, et une
        gamme complète de services, nous vous accompagnons à chaque étape pour assurer
        le succès de votre projet.
      </p>

      <div className="pourquoi-choisir-cards">
        {/* Card 1 */}
        <div className="pourquoi-choisir-card card-1">
          <div className="pourquoi-icon-container icon-container-1">
            <img src={IconWhy1} alt="Approche Innovante Icon" />
          </div>
          <h3>Approche Futuriste et Innovante</h3>
          <p>
            Nous adoptons une vision moderne pour chaque projet, en restant à la pointe
            des dernières technologies et tendances numériques.
          </p>
        </div>

        {/* Card 2 */}
        <div className="pourquoi-choisir-card card-2">
          <div className="pourquoi-icon-container icon-container-2">
            <img src={IconWhy2} alt="Flexibilité Dynamique Icon" />
          </div>
          <h3>Flexibilité Dynamique</h3>
          <p>
            Que ce soit pour des formations IT ou des services digitaux, nous nous
            adaptons rapidement à vos besoins et offrons des solutions sur mesure.
          </p>
        </div>

        {/* Card 3 */}
        <div className="pourquoi-choisir-card card-3">
          <div className="pourquoi-icon-container icon-container-3">
            <img src={IconWhy3} alt="Éventail Complet Icon" />
          </div>
          <h3>Un Éventail Complet de Services</h3>
          <p>
            TeamElGhazi vous propose un large éventail de services, vous évitant de
            chercher plusieurs prestataires. Tout est centralisé chez nous pour une
            expérience fluide.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PourquoiChoisir;
