import React from 'react';
import './DifferenceSection.css';

// Importing overlay images and card images from assets
import OrangeEffectTop from '../../../assets/digital/orange-effect-up.png';
import OrangeEffectBottom from '../../../assets/digital/orange-effect-bottom.png';
import Card1 from '../../../assets/digital/card1.png';
import Card2 from '../../../assets/digital/card2.png';

const DifferenceSection = () => {
  return (
    <section className="difference-section">
      <h2>Comment TEAMELGAHZI Fait La <span className="difference-highlight">Différence</span></h2>
      <p>Découvrez Comment Nous Aidons Nos Clients À Se Démarquer Dans L’Univers Digital</p>

      <div className="difference-cards-container">
        {/* Orange overlays */}
        <img src={OrangeEffectTop} alt="Top Overlay" className="overlay-top" />
        <img src={OrangeEffectBottom} alt="Bottom Overlay" className="overlay-bottom" />

        <div className="difference-card">
          <img src={Card1} alt="Experience Card" />
          <div className="difference-card-content">
            <h3>7+</h3>
            <p>Années d'expérience combinée</p>
            <span>Des experts passionnés qui maîtrisent les dernières tendances pour vous apporter des solutions innovantes et sur mesure.</span>
          </div>
        </div>

        <div className="difference-card">
          <img src={Card2} alt="Cost Saving Card" />
          <div className="difference-card-content">
            <h3>60%</h3>
            <p>D’économie sur les coûts</p>
            <span>Une solution abordable sans compromis sur la qualité, avec des tarifs compétitifs.</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DifferenceSection;





