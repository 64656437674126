import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './WhatWeDo.css';
import Icon1 from '../../assets/icons/icon 1.svg'; // Update paths as needed
import Icon2 from '../../assets/icons/icon 2.svg';
import Icon3 from '../../assets/icons/icon 3.svg';
import StopwatchIcon from '../../assets/icons/stopwatch.svg';
import ArrowIcon from '../../assets/icons/arrow.svg';

const WhatWeDo = () => {
  return (
    <div className="what-we-do" id="what-we-do-section">
      {/* Header Section */}
      <div className="what-we-do-header">
        <h1>Ce Que Nous Faisons</h1>
        <p>Chez TeamElGhazi, Nous Proposons Une Solution Tout-En-Un Pour Répondre À Vos Besoins Digitaux Et IT</p>
      </div>

      {/* Cards Section */}
      <div className="cards-container">
        {/* First Card: Digital Solutions */}
        <div className="card small-card">
          <div className="card-header">
            <span>Solutions Digitales Personnalisées</span>
          </div>
          <div className="icon-group">
            <img src={Icon1} alt="Icon 1" className="icon"/>
            <img src={Icon2} alt="Icon 2" className="icon"/>
            <img src={Icon3} alt="Icon 3" className="icon"/>
          </div>
          <h3>Augmentez votre <span className="highlight">visibilité</span> et engagez votre <span className="highlight">audience</span></h3>
          <p>Nous créons des solutions digitales sur mesure pour maximiser votre présence en ligne...</p>
          {/* Wrap button with Link to keep the button for styling */}
          <Link to="/digital">
            <button className="nv-button">DÉCOUVREZ NOS SERVICES DIGITAUX</button>
          </Link>
        </div>

        {/* Second Card: Support */}
        <div className="card small-card">
          <div className="card-header">
            <span>Support Technique Personnalisé</span>
          </div>
          <img src={StopwatchIcon} alt="Stopwatch Icon" className="icon-stopwatch"/>
          <h3>Garantissez la performance continue de vos <span className="highlight">systèmes</span></h3>
          <p>Nous créons des solutions digitales sur mesure pour maximiser votre présence en ligne...</p>
          {/* Wrap button with Link to keep the button for styling */}
          <Link to="/Services">
            <button className="nv-button-alt">DÉCOUVREZ NOS SERVICES DE SUPPORT</button>
          </Link>
        </div>

        {/* Third Card: IT and Digital Training */}
        <div className="card large-card">
          <div className="card-header">
            <span>Formation IT Et Digitale</span>
          </div>
          <img src={ArrowIcon} alt="Arrow Icon" className="icon-arrowontarget"/>
          <h3>Développez les compétences numériques de vos <span className="highlight">équipes</span></h3>
          <p>Nos formations sur mesure en IT et digital permettent à vos collaborateurs de maîtriser les outils essentiels...</p>
          {/* Wrap button with Link to keep the button for styling */}
          <Link to="/Formation">
            <button className="nv-button">EXPLOREZ NOS FORMATIONS</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
