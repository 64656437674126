import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]); // When location (route) changes, trigger the scroll

  return null; // This component doesn't render anything visible
};

export default ScrollToTop;
