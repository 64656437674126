import React from 'react';
import './HeroSectionDigital.css';
import adsIcon from '../../../assets/digital/ads-icon.png';
import brandingIcon from '../../../assets/digital/branding-icon.png';
import websiteIcon from '../../../assets/digital/website-icon.png';
import marketingIcon from '../../../assets/digital/marketing-icon.png';
import mouseIcon from '../../../assets/digital/mouse-icon.png';
import arrowDownIcon from '../../../assets/digital/arrow-down-icon.svg';

const HeroSectionDigital = () => {
  return (
    <section className="hero-section-digital">
      <h1>
        Boostez Votre Présence Avec <br />
        Nos <span className="highlighted">
          Solutions <span className="digital-text">Digitales</span>
        </span>
        <img src={mouseIcon} alt="Mouse Icon" className="mouse-icon-inline" />
      </h1>
      <p>
        Propulsez Votre Marque Au Sommet Avec Des Solutions Digitales Personnalisées,
        Conçues Pour Attirer, Engager Et Fidéliser Vos Clients
      </p>
      <div className="explore-section">
        <div className="explore-box">
          <img src={arrowDownIcon} alt="Arrow Down" className="arrow-icon" />
          <span className="explore-text">
            Explorez Nos Services Et Donnez Vie À Votre Ambition Digitale
          </span>
        </div>
      </div>
      {/* Floating icons */}
      <div className="floating-icons">
        <img src={adsIcon} alt="Ads Icon" className="icon ads-icon" />
        <img src={brandingIcon} alt="Branding Icon" className="icon branding-icon" />
        <img src={websiteIcon} alt="Website Icon" className="icon website-icon" />
        <img src={marketingIcon} alt="Marketing Icon" className="icon marketing-icon" />
      </div>
    </section>
  );
};

export default HeroSectionDigital;