import React from 'react';
import HeroSectionService from '../components/Service/HeroSectionService/HeroSectionService';
import ServiceCards from '../components/Service/ServicesCards/ServicesCards';
import ContactSection from '../components/ContactSection/ContactSection';
import FooterSection from '../components/Footer/Footer';
import './Services.css';

const Services = () => {
  return (
    <div className="services-page">
    <HeroSectionService />
    <ServiceCards />
    <ContactSection />
    <FooterSection />
    </div>
  );
};

export default Services;
