import React from 'react';
import HeroSection from '../components/HeroSection/HeroSection';
import WhatWeDo from '../components/WhatWeDo/WhatWeDo';
import WhyChooseUs from '../components/WhyChooseUs/WhyChooseUs';
import WhatWeCanDo from '../components/WhatWeCanDo/WhatWeCanDo';
import FAQ from '../components/FAQ/FAQ';
import Boost from '../components/Boost/Boost';
import ContactSection from '../components/ContactSection/ContactSection';
import FooterSection from '../components/Footer/Footer';

import './Home.css';

const Home = () => {
  return (
    <div className="home-page">
      <HeroSection />
      <Boost />
      <WhatWeDo id="what-we-do-section"/>
      <WhyChooseUs />
      <WhatWeCanDo />
      <FAQ />
      <ContactSection />
      <FooterSection />
    </div>
  );
};

export default Home;
