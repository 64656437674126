import React from 'react';
import boostImage from '../../assets/hero-image.png';
import './Boost.css';

const Boost = () => {
  return (
    <section className="boost-section">
      <div className="boost-overlay">
        <img src={boostImage} alt="Background" className="boost-image" />
        <div className="boost-content">
          <div className="boost-content-left">
            <h1 className="boost-title">Faire Briller Votre Entreprise Dans L'Espace Numérique</h1>
          </div>
          <div className="boost-content-right">
            <button className="boost-button">Boostez Votre Présence Digitale</button>
            <p className="boost-description">
              Chez TeamElGhazi, Nous Aidons Les Entreprises À Prospérer Dans Le Monde Numérique.
              Grâce À Des Solutions Digitales Personnalisées, Une Formation IT Experte, Et Un Support Dédié,
              <br /><br />
              Nous Faisons Briller Votre Marque Dans Un Marché Compétitif !
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Boost;
